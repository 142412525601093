import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import calendarSettingsParams, {
  ISettingsParams,
} from '../BookingCalendar/settingsParams';
import { AccessibilityHtmlTags } from '../../utils/accessibility/constants';

type CalendarWidgetSettingsOverride = Pick<
  ISettingsParams,
  'headerTitleHtmlTag' | 'sectionHeaderHtmlTag'
>;

export const calendarWidgetSettingsParamsDefinitions = {
  headerTitleHtmlTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
  },
  sectionHeaderHtmlTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => AccessibilityHtmlTags.ThirdHeading,
  },
};

const calendarWidgetSettingsParams =
  createSettingsParams<calendarwidgetsettingsoverride>(
    calendarWidgetSettingsParamsDefinitions,
  );

export default {
  ...calendarSettingsParams,
  ...calendarWidgetSettingsParams,
};
</calendarwidgetsettingsoverride>